import { template as template_032ce168a24943219b6705a77425436c } from "@ember/template-compiler";
const FKLabel = template_032ce168a24943219b6705a77425436c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
