import { template as template_8ca50f0f0cd042d8a948c605867dee9f } from "@ember/template-compiler";
const WelcomeHeader = template_8ca50f0f0cd042d8a948c605867dee9f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
