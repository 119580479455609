import { template as template_9955e0cfff8c413c97774eae837071f3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9955e0cfff8c413c97774eae837071f3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
