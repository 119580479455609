import { template as template_acd7e7571b2441a9a03ac30abb8198bf } from "@ember/template-compiler";
const SidebarSectionMessage = template_acd7e7571b2441a9a03ac30abb8198bf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
